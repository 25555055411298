import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
import BDC101 from "./ReportTabs/BDC101";
import POPastLeadTime from "./ReportTabs/POPastLeadTime";
import LiveWIP from "./ReportTabs/LiveWIP";
import DailyStageWise from "./ReportTabs/DailtStageWise";
import PendingWithSupplier from "./ReportTabs/PendingWithSupplier";
import "./Reports.scss";
import DeletedProdOrders from "./ReportTabs/DeletedProdOrders";
import { getStageDDL } from "../../../Redux/API/api_Common";
import { getSupplierData } from "../../../Redux/API/api_SupplierMaster";
import { getProductData } from "../../../Redux/API/api_ProductMaster";
import { useSelector } from "react-redux";
import { getMlfbData } from "../../../Redux/API/api_MlfbMaster";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import StageWiseLeadTime from "./ReportTabs/StageWiseLeadTime";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Reports() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const siteToken = useSelector((state) => state.saveTokenReducer);
   const azureToken = useSelector((state) => state.saveAzureTokenReducer);
   const userdetails = useSelector(
     (state) => state.storeUserDetailsReducer.userData
   );
   const UID = useSelector((state) => state.saveUserIdReducer);
   const [supplierData, setSupplierData] = useState([]);
   const [productFamily, setProductFamily] = useState([]);
   const [mlfbData, setmlfbData] = useState([]);
   const [stageData, setStageData] = useState([]);

   function MlfbDetails() {
     getMlfbData(
       siteToken,
       UID.id,
       userdetails.userEmail,
       userdetails.gid,
       userdetails.roleName,
       userdetails.roleId,
       azureToken.token
     )
       .then((response) => {
         setmlfbData(response);
       })
       .catch((error) => {
         handleSnackOpen("Error : " + error, "error");
       });
   }
   function getSupplierDetails() {
     getSupplierData(
       siteToken,
       UID.id,
       userdetails.userEmail,
       userdetails.gid,
       userdetails.roleName,
       userdetails.roleId,
       azureToken.token
     )
       .then((response) => {
         setSupplierData(response);
       })
       .catch((error) => {
         handleSnackOpen("Error : " + error, "error");
       });
   }
   function getProductDetails() {
     getProductData(
       siteToken,
       UID.id,
       userdetails.userEmail,
       userdetails.gid,
       userdetails.roleName,
       userdetails.roleId,
       azureToken.token
     )
       .then((response) => {
         setProductFamily(response);
       })
       .catch((error) => {
         handleSnackOpen("Error : " + error, "error");
       });
   }
   function getStageDetails() {
     getStageDDL(
       siteToken,
       UID.id,
       userdetails.userEmail,
       userdetails.gid,
       userdetails.roleName,
       userdetails.roleId,
       azureToken.token
     )
       .then((response) => {
         setStageData(response);
       })
       .catch((error) => {
         handleSnackOpen("Error : " + error, "error");
       });
   }
  useEffect(() => {
    if (siteToken) {
      MlfbDetails();
      getSupplierDetails();
      getProductDetails();
      getStageDetails();
    }
  }, [siteToken]);

  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <h3>Reports With Custom Filters</h3>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="MCB Scrollable List"
        >
          <Tab label="BDC 101" {...a11yProps(0)} />
          <Tab
            label="Total Lead Time Elapsed Of Current Orders"
            {...a11yProps(1)}
          />
          <Tab label="Live WIP Stage-wise" {...a11yProps(2)} />
          <Tab label="Daily Stage-wise" {...a11yProps(3)} />
          <Tab label="Pending Orders With Supplier" {...a11yProps(4)} />
          <Tab label="% Of Deleted Production Orders" {...a11yProps(5)} />
          <Tab label="Stage-wise LeadTime" {...a11yProps(6)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <BDC101
          mlfbData={mlfbData}
          supplierData={supplierData}
          productData={productFamily}
        ></BDC101>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <POPastLeadTime
          mlfbData={mlfbData}
          supplierData={supplierData}
          productData={productFamily}
        ></POPastLeadTime>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LiveWIP
          mlfbData={mlfbData}
          supplierData={supplierData}
          productData={productFamily}
          stageData={stageData}
        ></LiveWIP>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DailyStageWise
          mlfbData={mlfbData}
          supplierData={supplierData}
          productData={productFamily}
          stageData={stageData}
        ></DailyStageWise>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <PendingWithSupplier
          supplierData={supplierData}
          productData={productFamily}
        ></PendingWithSupplier>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DeletedProdOrders
          supplierData={supplierData}
          productData={productFamily}
        ></DeletedProdOrders>
      </TabPanel>
      <TabPanel value={value} index={6}>
      <StageWiseLeadTime
        supplierData={supplierData}
        productData={productFamily}
      ></StageWiseLeadTime>
    </TabPanel>
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}
