import { baseApiURL } from "../../Utilities/utility";
import axios from "axios";

export function getPoListDataAck(
  siteToken,
  id,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return axios
    .get(
      baseApiURL +
        "/ProdOrder/GetPOsAfterAcknowledge?id=" +
        id +
        "&userId=" +
        encodeURIComponent(userId),
      options
    )
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getPoListDataDispatch(
  siteToken,
  id,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return axios
    .get(
      baseApiURL +
        "/ProdOrder/GetDispatchPos?id=" +
        id +
        "&userId=" +
        encodeURIComponent(userId),
      options
    )
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function AcknowledgePO(
    siteToken,
    azureToken,
    encUserId,
    id,
    userId,
    userEmail,
    gid,
    roleName,
    roleId

) {
    const options = {
        method: "post",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
    };
    return fetch(
        baseApiURL + "/ProdOrder/AcknowledgePO?encUserId=" + encodeURIComponent(encUserId) + "&id=" + id + "&userId=" + userId,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function addDispatchData(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data
) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Supplier/PostDispatchDetails?userId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateDispatchData(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data
    
    
) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Supplier/PutDispatchDetails?userId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getQRData(
    siteToken,
    id,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    azureToken
) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
    };
    return fetch(
        baseApiURL +
        "/Supplier/GenerateQR?id=" +
        id +
        "&userId=" +
        encodeURIComponent(userId),
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}