import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import AcknowledgeProductionOrder from "./ComponentsAckOrder/AcknowledegProductionOrder";
import "./AckOrder.scss";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "30px",
  }
}));

export default function AckOrder() {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div className={classes.root}>

      <Grid>
        <AcknowledgeProductionOrder />
        <div className='ackFooter'>
          <Button className="pt-button--secondary" onClick={() => {
            history.push({
              pathname: "/poworkflow",
            })
          }}>Go To PO Workflow</Button>

          <Button className="pt-button--secondary" onClick={() => {
            history.push({
              pathname: "/dispatchdetails",
            })
          }}>Next</Button>
        </div>
      </Grid>
    </div>
  );
}
