import {
  baseApiURL,
  userEmail,
  userRole,
  userGid,
} from "../../Utilities/utility";


export function getMCBWorkflowData(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL +
      "/WorkflowGRN/MCBWorkFlow?userId=" +
      encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function get5STWorkflowData(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL +
      "/WorkflowGRN/5STWorkFlow?userId=" +
      encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getFuseWorkflowData(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    // headers: { Authorization: "Bearer " + siteToken.token },
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL +
      "/WorkflowGRN/FuseWorkFlow?userId=" +
      encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getRCCBWorkflowData(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL +
      "/WorkflowGRN/RCCBWorkFlow?userId=" +
      encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getRCBOWorkflowData(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL +
      "/WorkflowGRN/RCBOWorkFlow?userId=" +
      encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getFuseAccessoriesWorkflowData(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL +
      "/WorkflowGRN/FuseAccessoriesWorkFlow?userId=" +
      encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
