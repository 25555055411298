import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import {
    getRoleData,
    deleteRoleData,
    updateRoleData,
} from "../../../../Redux/API/api_RoleMaster";
import { tableOptions } from "../../../Components/CommonComponents/common_components";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { tableIcons } from "../../../../Utilities/utility";

export default function RoleMaster() {
    const userdetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function isRoleExists(value) {
        const obj = tableData.find((c) => c.roleName === value);
        return obj ? true : false;
    }
    function isRoleExists(value, id) {
        const obj = tableData.find((c) => c.roleName === value);
        return obj && obj.roleId !== id ? true : false;
    }
    function isAlphanumericSpecial(value) {
        const re = /^[(aA-zZ)(0-9)(\- . &)]*$/;
        return re.test(value);
    }
    function validateRoleName(value, id) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isAlphanumericSpecial(value)
                ? { isValid: false, helperText: "Alphanumeric,space,dot,&,-" }
                : (!id && isRoleExists(value)) || (id && isRoleExists(value, id))
                    ? { isValid: false, helperText: "Already exists" }
                    : { isValid: true, helperText: "" };
    }

    var columns = [
        {
            title: "Role Id",
            field: "roleId",
            hidden: true,
        },
        {
            title: "Role Name",
            field: "roleName",
            validate: (rowData) => validateRoleName(rowData.roleName, rowData.roleId),
        },
    ];
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    function getTableData() {
        setIsLoader(true);
        getRoleData(
            siteToken,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            azureToken.token
        )
            .then((response) => {
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    const enterKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", enterKeyDown);

        getTableData();

        return () => {
            document.removeEventListener("keydown", enterKeyDown);
        };
    }, [userdetails]);

    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedinUserId = userdetails.userId;
        updateRoleData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            newTableData
        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        oldTableData.loggedinUserId = userdetails.userId;
        deleteRoleData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            oldTableData
            
        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function getDisableTooltip(value, type) {
        return value === true
            ? "Role already associated with users"
            : type === "Edit"
                ? "Edit"
                : "Delete";
    }
    return (
        <div>
            <div className="App">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <MaterialTable
                            className="role-table"
                            title=""
                            columns={columns}
                            data={tableData}
                            icons={tableIcons}
                            isLoading={isLoader}
                            options={tableOptions}
                            editable={{
                                isDeletable: (rowData) => !rowData.isReadOnlyRole,
                                deleteTooltip: (rowData) =>
                                    getDisableTooltip(rowData.isReadOnlyRole, "Delete"),
                                onRowUpdate: (newTableData, oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowUpdate(
                                            newTableData,
                                            oldTableData,
                                            resolve,
                                            reject
                                        );
                                    }),
                                onRowDelete: (oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDelete(oldTableData, resolve, reject);
                                    }),
                            }}
                        />
                        <>
                            <SnackbarCustom
                                open={open}
                                message={snackMessage}
                                alertType={alertType}
                                handleClose={handleClose}
                            />
                        </>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
