import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Button,
  makeStyles,
  Container,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Snackbar from "../Components/Snackbar/Snackbar";
import "./StageScanner.scss";
import { bdcScanner } from "../../Redux/API/api_scannerStages";
import Loading from "../Components/Loading/Loading";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  paperSty: {
    padding: "30px",
    marginTop: "10px",
  },
  childGrid: {
    marginTop: "10px",
  },
  btnGrid: {
    justifyContent: "center",
    marginBottom: "1rem",
  },
}));

export default function StageBDCScanner() {
  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
  const classes = useStyles();
  const siteToken = useSelector((state) => state.saveTokenReducer);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [qrDataInput] = useState("");
  let [arr, setArr] = useState([]);
  const [finalQuantity, setFinalQuantity] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  let [data, setData] = useState("");
  const [finalQuantityHelper, setFinalQuantityHelper] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const location = useLocation();
  const prodOrderNo = useState(location.state.rowData);
  let history = useHistory();

  function isInteger(value) {
    const re = /^\d*$/;
    return re.test(value);
  }

  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (e) => {
    setOpenSnackbar(false);
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    if (e.target.outerText === "CANCEL") {
      setOpen(false);
    } else if (e.target.outerText === "CLOSE") {
      setOpenDialog(false);
    } else {
      return;
    }
  };
  const handleCloseNewField = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpenDialog(false);
  };
  const handleAcceptNewField = () => {
    setDisableSubmit(false);
    const arrUpdated = arr.map((item) => {
      item.stageName = "BDC";
      item.finalQty = parseInt(finalQuantity);
      item.remarks = remarks;
      item.loggedInUserId = userdetails.userId;
      return item;
    });
    setArr([...arrUpdated]);
    setOpen(true);
  };

  const onChange = (event) => {
    switch (event.target.id) {
      case "finalQuantity":
        setFinalQuantity(event.target.value);
        if (!event.target.value) {
          setFinalQuantityHelper("Required");
        } else {
          if (!isInteger(event.target.value)) {
            setFinalQuantityHelper("Only intergers");
          } else if (event.target.value > data.quantityInBrks) {
            setFinalQuantityHelper("max quantity exceeded");
          } else {
            setFinalQuantityHelper("");
          }
        }
        break;
      case "remarks":
        setRemarks(event.target.value);
        break;
      default:
        break;
    }
  };

  function handleQRDataInput(e) {
    let dataTextArea = e.target.value;
    let jsonData;
    if (dataTextArea.includes("'")) {
      let datanew = dataTextArea.substr(1, dataTextArea.length - 2);
      jsonData = JSON.parse(datanew);
    } else {
      jsonData = JSON.parse(dataTextArea);
    }
    if (
      prodOrderNo[0].productionOrderNumber === jsonData.productionOrderNumber
    ) {
      if (arr.length === 0) {
        setData(jsonData);
        setDisableSubmit(false);
        setArr((arr) => [
          ...arr,
          {
            ...jsonData,
            stageName: "BDC",
            finalQty: 0,
            remarks: "",
          },
        ]);
      } else if (arr.length >= prodOrderNo[0].paperCount) {
        handleSnackOpen(
          `All scans completed for Production Order Number ${prodOrderNo[0].productionOrderNumber}`,
          "success"
        );
      } else {
        if (prodOrderNo[0].poleCount === 1) {
          setData(jsonData);
          setDisableSubmit(false);
          setArr((arr) => [
            ...arr,
            {
              ...jsonData,
              stageName: "BDC",
              finalQty: 0,
              remarks: "",
            },
          ]);
        } else if (arr.find((item) => item.polarity === jsonData.polarity)) {
          handleSnackOpen("QR code already scanned", "error");
        } else {
          setData(jsonData);
          setDisableSubmit(false);
          setArr((arr) => [
            ...arr,
            {
              ...jsonData,
              stageName: "BDC",
              finalQty: 0,
              remarks: "",
            },
          ]);
        }
      }
    } else {
      handleSnackOpen("PO number mismatch", "error");
    }
  }

  function postBDCScanData(data) {
    bdcScanner(
      siteToken,
        azureToken.token,
        UID.id,
      userdetails.userEmail,
      userdetails.gid,
      userdetails.roleName,
      userdetails.roleId, 
      data
    )
      .then((response) => {
        if (response) {
          setIsLoader(false);
          setOpen(false);
          setOpenDialog(false);
          handleSnackOpen();
          setSnackMessage("Scanned data successfully submitted");
          setAlertType("Success");
          setArr([]);
          setTimeout(() => {
            history.goBack();
          }, 3000);
        } else {
          setIsLoader(false);
          handleSnackOpen();
          setSnackMessage("Error while submitting data");
          setAlertType("error");
          setArr([]);
        }
      })
      .catch((error) => {
        handleSnackOpen("Error : " + error, "error");
        setIsLoader(false);
      });
  }

  function getData() {
    setIsLoader(true);
    postBDCScanData(arr);
  }
  const enterKeyDown = (e) => {
    if (e.which === 13 || e.which === 10) {
      e.preventDefault();
      return false;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", enterKeyDown);

    return () => {
      document.removeEventListener("keydown", enterKeyDown);
    };
  }, []);
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <form>
          <Grid container spacing={1} className={classes.childGrid}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                label="QR Code Data"
                variant="filled"
                value={qrDataInput}
                onChange={handleQRDataInput}
                id="qrCodeData"
                className="pd-2 mb-1"
                placeholder="Tap here and then scan using QR scanner"
              />
            </Grid>

            <Grid item xs={12} className="pl-2">
              <div className="qr-tables">
                {arr.map((item, index) => (
                  <table className="scanner-table width-100 mt-1" key={index}>
                    <tbody>
                      <tr className="scanner-row">
                        <td className="scanner-td">Invoice No</td>
                        <td className="scanner-td">
                          {item.supplierInvoiceNumber}
                        </td>
                      </tr>
                      <tr>
                        <td className="scanner-td">Date</td>
                        <td className="scanner-td">
                          {item.supplierInvoiceDate}
                        </td>
                      </tr>
                      <tr>
                        <td className="scanner-td">MLFB</td>
                        <td className="scanner-td">{item.mlfb}​​​​​​​</td>
                      </tr>
                      <tr>
                        <td className="scanner-td">Rating</td>
                        <td className="scanner-td">{item.rating}​​​​​​​</td>
                      </tr>
                      <tr>
                        <td className="scanner-td">Packing</td>
                        <td className="scanner-td">{item.packingType}</td>
                      </tr>
                      <tr>
                        <td className="scanner-td">Prod O.</td>
                        <td className="scanner-td">
                          {item.productionOrderNumber}
                        </td>
                      </tr>
                      <tr>
                        <td className="scanner-td">Polarity</td>
                        <td className="scanner-td">{item.polarity}​​​​​​​</td>
                      </tr>
                      <tr>
                        <td className="scanner-td">Quantity</td>
                        <td className="scanner-td">{item.packedOk}</td>
                      </tr>
                    </tbody>
                  </table>
                ))}
              </div>
            </Grid>

            <Grid container spacing={1} className={classes.btnGrid}>
              <Button
                className="pt-button--secondary"
                style={{ marginRight: "15px", position: "left" }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Back
              </Button>
              <Button
                className="pt-button--secondary"
                onClick={handleClickOpen}
                disabled={
                  disableSubmit ||
                  !(arr && arr.length === prodOrderNo[0].paperCount)
                }
              >
                Submit Scanned Data
              </Button>
            </Grid>
            <Dialog
              open={openDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <h3>
                    Production Order Number : {data.productionOrderNumber}
                  </h3>
                  <h5>
                    Batch Quantity : {data.quantityInBrks}
                    <br />
                    Dispatched Quantity : {data.packedOk}
                  </h5>
                  <TextField
                    type="number"
                    className="mb-1"
                    variant="filled"
                    fullWidth
                    required
                    label="Final Quantity"
                    helperText={finalQuantityHelper}
                    error={finalQuantityHelper.length !== 0}
                    onChange={onChange}
                    id="finalQuantity"
                    value={finalQuantity}
                  />
                  <TextField
                    type="text"
                    className=""
                    variant="filled"
                    fullWidth
                    label="Remarks"
                    onChange={onChange}
                    id="remarks"
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseNewField}
                  className="pt-button--secondary"
                >
                  Close
                </Button>
                <Button
                  onClick={handleAcceptNewField}
                  className="pt-button--primary"
                  disabled={!finalQuantity || finalQuantityHelper}
                >
                  OK
                </Button>
              </DialogActions>
              <div className="pd-1">
                <p>Note :-</p>
                <ul className="ul-no-bg">
                  <li>
                    Please make sure Final Quantity does not exceed Batch
                    Quantity statement
                  </li>
                </ul>
              </div>
            </Dialog>
          </Grid>
          <Snackbar
            open={openSnackbar}
            message={snackMessage}
            alertType={alertType}
            handleClose={handleCloseSnackbar}
          />
          {isLoader ? <Loading /> : <React.Fragment></React.Fragment>}
        </form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to submit this data?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="pt-button--secondary">
              Cancel
            </Button>
            <Button onClick={() => getData()} className="pt-button--primary">
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}
