import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./Admin.scss";
import { AdminstratorOptions } from "../../Utilities/utility";

export default function Admin() {
  return (
    <React.Fragment>
      <h3>Administrator</h3>
      <Grid container spacing={2}>
        {AdminstratorOptions.map((adminItem) => (
          <Grid item xs={12} sm={3} key={adminItem.adminMenuId}>
            <Link to={adminItem.adminMenuLink} key={adminItem.adminMenuId}>
              <div className="adminMenu-item-container">
                <div className="adminMenu-item-text-block">
                  <span>{adminItem.adminMenuName}</span>
                </div>
              </div>
            </Link>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
