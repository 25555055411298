import { baseApiURL } from "../../Utilities/utility";

export function getRolesDDL(
  siteToken,
  userEmail,
  gid,
  roleName,
  userId,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL + "/Common/GetRolesDDL?userId=" + encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getSupplierCodesDDL(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Common/GetSupplierCodeDDL?userId=" +
      encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getMLFBsDDL(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL + "/Common/GetMLFBDDL?userId=" + encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getLivePOsDDL(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL + "/Common/GetPOsDDL?userId=" + encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getQRDataForReprint(
  siteToken,
  id,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Common/GenerateQRForReprint?id=" +
      id +
      "&userId=" +
      encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getSupplierCodeDDL(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Common/GetSupplierCodeDDL?userId=" +
      encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getStageDDL(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL + "/Common/GetStageDDL?userId=" + encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}