import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Box } from '@material-ui/core';
import MLFBMaster from './MasterTables/MLFBMaster';
import RoleMaster from './MasterTables/RoleMaster';
import SupplierMaster from './MasterTables/SupplierMaster';
import ProductMaster from './MasterTables/ProductMaster';
import LeadTimeMaster from './MasterTables/LeadTimeMaster';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function AdminMasterTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="MCB Scrollable List"
                >
                    <Tab label="MLFB Master" {...a11yProps(0)} />
                    <Tab label="Lead Time Master" {...a11yProps(1)} />
                    <Tab label=" Role Master" {...a11yProps(2)} />
                    <Tab label="Supplier Master" {...a11yProps(3)} />
                    <Tab label="Product Master" {...a11yProps(4)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <MLFBMaster />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <LeadTimeMaster />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <RoleMaster />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <SupplierMaster />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <ProductMaster />
            </TabPanel>
        </div>
    );
}

