import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Box } from '@material-ui/core';
import ProductMCB from "./Products/ProductMCB";
import Product5ST from "./Products/Product5ST";
import ProductFuse from "./Products/ProductFuse";
import ProductFuseAccessories from "./Products/ProductFuseAccessories";
import ProductRCCB from "./Products/ProductRCCB";
import ProductRCBO from "./Products/ProductRCBO";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function POWorkflow() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <h3>Production Order Workflow</h3>
      <AppBar position="static">
        <Tabs

          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="MCB Scrollable List"
        >
          <Tab label="MCB" {...a11yProps(0)} />
          <Tab label="5ST" {...a11yProps(1)} />
          <Tab label="Fuse" {...a11yProps(2)} />
          <Tab label="Fuse Accessories" {...a11yProps(3)} />
          <Tab label="RCCB" {...a11yProps(4)} />
          <Tab label="RCBO" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ProductMCB />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Product5ST />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ProductFuse />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ProductFuseAccessories />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ProductRCCB />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <ProductRCBO />
      </TabPanel>
    </div>
  );
}
