import React, { useState } from "react";
import CountdownTimer from "react-component-countdown-timer";

export default function Timer(props) {

  const [countDownTime] = useState(parseInt(props.expiredTime - Date.now()) / 1000);
  return (
    <CountdownTimer
      className="fw-bold clr-dark-blue"
      count={countDownTime}
      border={true}
      showTitle={false}
      noPoints={false}
      responsive={true}
      hideDay={true}
      hideHours={true}
      backgroundColor="#E8F8F8"
    />
  );
}
