import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  MenuItem,
  Button,
  makeStyles,
} from "@material-ui/core";
import FileSaver from "file-saver";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loading/Loading";
import Snackbar from "../../../Components/Snackbar/Snackbar";
import { PendingWithSupplier } from "../../../../Redux/API/api_reports";

const useStyles = makeStyles(() => ({
  paperSty: {
    padding: "30px",
    marginTop: "10px",
  },
  btnGrid: {
    justifyContent: "center",
    marginTop: "2rem",
  },
}));

export default function SupplierReport(props) {
  const classes = useStyles();
  const [isLoader, setIsLoader] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const siteToken = useSelector((state) => state.saveTokenReducer);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const [supplierData, setSupplierData] = useState([]);
  const [family, setFamily] = useState([]);
  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
  const [inputData, setInputData] = React.useState({
    productId: null,
    supplierId: null,
  });
  const [errorTexts, setErrorTexts] = useState({
    productId: "",
    supplierId: "",
    loggedInUserId: userdetails.userId,
  });

  const handleSnackOpen = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (e) => {
    setOpenSnackbar(false);
  };
  function getSupplierReportDetails() {
    inputData.productId = !inputData.productId
      ? 0
      : parseInt(inputData.productId);
    inputData.supplierId = !inputData.supplierId
      ? 0
      : parseInt(inputData.supplierId);
    PendingWithSupplier(
        siteToken,
        azureToken.token,
        UID.id,
        userdetails.userEmail,
        userdetails.gid,
        userdetails.roleName,
        userdetails.roleId,
        inputData
      
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.byteLength <= 500) {
            setIsLoader(false);
            handleSnackOpen();
            setSnackMessage("No Records are Found ");
            setAlertType("success");
          } else {
            var blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(blob, "SupplierReport.xlsx");
            setIsLoader(false);
            handleSnackOpen();
            setSnackMessage("Supplier Report downloaded");
            setAlertType("Success");
          }
        } else {
          handleSnackOpen();
          setSnackMessage("Report download failed");
          setAlertType("Alert");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        handleSnackOpen();
        setSnackMessage("Error: ", error);
        setAlertType("error");
      });
  }

  function handleChange(e) {
    setErrorTexts({
      ...errorTexts,
      [e.target.name]: "",
    });
    const value = e.target.value;
    setInputData({
      ...inputData,
      [e.target.name]: value,
    });
  }

  const downloadReport = (e) => {
    setIsLoader(true);
    e.preventDefault();
    if (!inputData.supplierId) {
      setIsLoader(false);
      setErrorTexts({
        ...errorTexts,
        supplierId: "*Mandatory",
      });
    } else {
      getSupplierReportDetails();
    }
  };

  useEffect(() => {
    if (props) {
      setFamily(props.productData);
      setSupplierData(props.supplierData);
    }
  }, [props]);

  return (
    <Paper className={classes.paperSty}>
      <form>
        <Grid container spacing={2} className="d-flex jc-center">
          <Grid item xs={12} sm={4}>
            <TextField
              name="productId"
              id="standard-select-currency"

              select
              fullWidth
              label="Product Family"
              value={inputData.productId}
              onChange={handleChange}
              variant="filled"
            >
              <MenuItem value="">
                <em>none</em>
              </MenuItem>
              {family.map((item) => (
                <MenuItem key={item.productId} value={item.productId}>
                  {item.productName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              name="supplierId"
              id="supplierId"
              select
              fullWidth
              label="Supplier"
              value={inputData.supplierId}
              error={
                errorTexts.supplierId && errorTexts.supplierId.length > 0
              }
              helperText={errorTexts.supplierId}
              onChange={handleChange}
              variant="filled"
            >
              <MenuItem value="">
                <em>none</em>
              </MenuItem>
              {supplierData.map((item) => (
                <MenuItem
                  key={item.supplierDetailsId}
                  value={item.supplierDetailsId}
                >
                  {item.supplierName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid container spacing={1} className={classes.btnGrid}>
            <Button
              onClick={downloadReport}
              className="pt-button--secondary bulk-upload-submit-btn"
              type="submit"
            >
              Download
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          open={openSnackbar}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleCloseSnackbar}
        />
        {isLoader ? <Loader /> : <React.Fragment></React.Fragment>}
      </form>
    </Paper>
  );
}
