import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import {
  getPoListDataAck,
  AcknowledgePO,
} from "../../../Redux/API/api_Supplier";
import { tableOptions } from "../../Components/CommonComponents/common_components";
import Loading from "../../Components/Loading/Loading";
import { tableIcons } from "../../../Utilities/utility";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";

export default function AcknowledegProductionOrder() {
  const [isLoader, setIsLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const siteToken = useSelector((state) => state.saveTokenReducer);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const UID = useSelector((state) => state.saveUserIdReducer);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };

  var columns = [
    {
      title: "PoID",
      field: "productionOrderDetailsId",
      hidden: true,
    },
    {
      title: "Delivery Date",
      field: "deliveryDate",
    },
    {
      title: "Production Order",
      field: "productionOrderNumber",
    },
    {
      title: "MLFBId",
      field: "mlfbId",
      hidden: true,
    },
    {
      title: "MLFB",
      field: "mlfb",
      cellStyle: {
        minWidth: "9rem",
        maxWidth: "9rem",
      },
    },
    {
      title: "Quantity in Breaker",
      field: "qtyInBreaker",
    },
    {
      title: "Purchase Order",
      field: "purchaseOrderNumber",
    },
    {
      title: "Item",
      field: "item",
    },
    {
      title: "Production Order Date",
      field: "poDate",
    },
    {
      title: "Material",
      field: "materialNumber",
    },
    {
      title: "Type",
      field: "type",
    },
    {
      title: "Rating",
      field: "rating",
    },
    {
      title: "Quantity In Pole",
      field: "qtyInPole",
    },
    {
      title: "Acknowledge",
      field: "acknowledge",
      render: (rowData) => getAcknoledgeButton(rowData),
    },
  ];

  function getTableData() {
    setIsLoader(true);
    if (userdetails && userdetails.supplierId) {
      getPoListDataAck(
        siteToken,
        userdetails.supplierId,
        UID.id,
        userdetails.userEmail,
        userdetails.gid,
        userdetails.roleName,
        userdetails.roleId,
        azureToken.token
      )
        .then((response) => {
          setTableData(response);
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
          handleSnackOpen("Error while fetching data from server", "error");
        });
    } else {
      setIsLoader(false);
      handleSnackOpen(
        "User must be assigned with a supplier code to Acknowledge data",
        "info"
      );
    }
  }

  function getAcknoledgeButton(data) {
    return (
      <Button
        className="pt-button--tertiary"
        onClick={() => acknowldgePO(data.productionOrderDetailsId)}>
        Acknowledge
      </Button>
    );
  }
  function acknowldgePO(id) {
    setIsLoader(true);
    AcknowledgePO(
        siteToken,
        azureToken.token,
        UID.id,
        id,
        userdetails.userId,
        userdetails.userEmail,
        userdetails.gid,
        userdetails.roleName,
        userdetails.roleId
      
    )
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          getTableData();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          getTableData();
        }
      })
      .catch((error) => {
        handleSnackOpen(error, "error");
        getTableData();
      });
  }

  useEffect(() => {
    getTableData();
  }, [userdetails]);

  return (
    <>
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
      <div>
        <h3>Acknowledge Production Order</h3>
        <MaterialTable
          title=""
          columns={columns}
          data={tableData}
          icons={tableIcons}
          options={tableOptions}
        />
      </div>
      {isLoader ? <Loading /> : <></>}
    </>
  );
}
