import { combineReducers } from "redux";

let userInitialState = {
  gid: "",
};
let azureToken = {
  token: "",
};
let encryptedId = {
  id: "",
};
let tokenInitialState = {
  token: "",
};
let userDataInitialState = {
  userData: {
    gid: "",
    roleId: 0,
    roleName: "",
    userEmail: "",
    userFirstName: "",
    userLastName: "",
    userId: 0,
  },
};
let errorMessageInitialState = "";

const authUserReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case "myIDLogin": {
      return {
        gid: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

const saveTokenReducer = (state = tokenInitialState, action) => {
  switch (action.type) {
    case "saveAccessToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

const saveAzureTokenReducer = (state = azureToken, action) => {
  switch (action.type) {
    case "saveAzureToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

const saveUserIdReducer = (state = encryptedId, action) => {
  switch (action.type) {
    case "encryptedId": {
      return {
        id: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

const storeUserDetailsReducer = (
  state = userDataInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "storeUserData": {
      return {
        userData: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};

const errorMessageReducer = (
  state = errorMessageInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "errorMessage": {
      return payLoad;
    }
    default: {
      return state;
    }
  }
};

let uploadResponseInitialState = {
  response: false,
  responseMsg: "",
};
const bulkUploadSuccessReducer = (
  state = uploadResponseInitialState,
  action
) => {
  switch (action.type) {
    case "bulkUploadSuccess": {
      return { ...action.payLoad };
    }
    default: {
      return state;
    }
  }
};

let isVerifiedOTPInitialState = false;
const isVerifiedOTPReducer = (state = isVerifiedOTPInitialState, action) => {
  switch (action.type) {
    case "isVerifiedOTP": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
};

let isForgotPasswordInitialState = false;
const isForgotPasswordReducer = (
  state = isForgotPasswordInitialState,
  action
) => {
  switch (action.type) {
    case "isForgotPassword": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
};

let custHeaderInitialState = "defaultHeader";
const custHeaderReducer = (state = custHeaderInitialState, action) => {
  switch (action.type) {
    case "custHeader": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
};

export const masterReducer = combineReducers({
  authUserReducer: authUserReducer,
  saveTokenReducer: saveTokenReducer,
  storeUserDetailsReducer: storeUserDetailsReducer,
  errorMessageReducer: errorMessageReducer,
  bulkUploadSuccessReducer: bulkUploadSuccessReducer,
  isVerifiedOTPReducer: isVerifiedOTPReducer,
  custHeaderReducer: custHeaderReducer,
  isForgotPasswordReducer: isForgotPasswordReducer,
  saveAzureTokenReducer: saveAzureTokenReducer,
  saveUserIdReducer: saveUserIdReducer,
});
