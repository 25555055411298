import { Switch, Route, Redirect } from "react-router-dom";
import Login from "../Pages/Login/Login";
import ChangePassword from '../Pages/ResetPassword/ChangePassword';

export const LoginRoute = (
    <Switch>

        <Route path="/" render={() => <Login />} />
    </Switch>
);

export const ResetPasswordRoute = (
    <Switch>
        <Redirect from="/signin-oidc*" to="/" />
        <Route exact path="/" render={() => <ChangePassword />} />
    </Switch>
);